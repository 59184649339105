import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import SEO from '../components/seo'

import Post from '../components/Post/Post'

const BlogPage = () => (
  <StaticQuery query={articlesQuery} render={data => {
    const { frontmatter, html, fields } = data.allMarkdownRemark.edges[0].node
    const { title, subTitle, image } = data.markdownRemark.frontmatter
    return (
      <>
        <SEO title="Blog | BluePes" keywords={[`bluepes`, `blog`, `articles`, `IT`]} />
        <BackgroundImage
          fluid={image.childImageSharp.fluid}
          className='background-image-blog general-page-background'
        >
          <div className="general-page-header wrapper">
            <h1 className="general-page-title">{title}</h1>
            <p className="general-page-description">
              {subTitle}
            </p>
          </div>
        </BackgroundImage>
        <section className="blog-page">
          <div className="general-page-wrapper">
            <article className="newest-article wrapper">
              <Link className="newest-article-wrapper" to={`/blog/${ fields.slug }`}>
                <div className="article-image">
                  <BackgroundImage
                    Tag="div"
                    fluid={frontmatter.image.childImageSharp.fluid}
                  />
                </div>
                <div className="article-context">
                  <h1 className="article-header">
                    {frontmatter.title}
                  </h1>
                  <div
                    className="article-date">
                    {frontmatter.date}
                  </div >
                  <div className="article-text"
                    dangerouslySetInnerHTML={{
                      __html: html
                    }}
                  />
                  <div className="link-to-article-page">
                      Read more
                  </div>
                </div>
              </Link>
            </article>
          </div>
          <div className="blog-articles">
            <div className="blog-articles-wrapper wrapper">
              {data.allMarkdownRemark.edges.slice(1).map(({ node }) => {
                return (
                  <Post
                    key={node.id}
                    title={node.frontmatter.title}
                    slug={`/blog/${ node.fields.slug }`}
                    image={node.frontmatter.image.childImageSharp.fluid}
                    date={node.frontmatter.date}
                  />
                )
              })}
            </div>
          </div>
        </section>
      </>
    )
  }}
  />
)

export const articlesQuery = graphql`
  query {
    allMarkdownRemark (
      limit: 1000
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {fileAbsolutePath: {regex: "/content/blog/"}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fluid(
                  maxWidth: 456, 
                  maxHeight: 456, 
                  quality: 80, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [224, 288, 344, 352, 456, 712]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    markdownRemark(
      fileAbsolutePath: {regex: "/partial/general-pages-header/general-blog-header.md/"}
    ) {
      frontmatter {
        title
        subTitle
        image {
          childImageSharp {
            fluid(
              maxWidth: 1920, 
              quality: 80,
              srcSetBreakpoints: [375, 480, 768, 1024, 1440, 1920]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default BlogPage
